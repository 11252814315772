import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import styled from "styled-components";
import { getIsWebView } from "../utils/webView";
import { KeyCloakContext } from "./App";
import Button from "./Button";

const PromoText = styled.p`
  color: ${({ theme }) => theme.colors.white};
`;
export const KeycloakLoginLinks = () => {
  const { t } = useTranslation();

  const {
    urls: { loginUrl, registerUrl },
  } = useContext(KeyCloakContext);
  if (getIsWebView()) {
    return null;
  }
  if (!loginUrl || !registerUrl) {
    return null;
  }
  return (
    <>
      <Box mb={2}>
        <Button width={1} href={loginUrl} variant="secondary">
          {t("login.loginButton", "Login")}
        </Button>
      </Box>
      <Box textAlign={"center"}>
        <PromoText>{t("login.orn", "Or")}</PromoText>
      </Box>
      <Box mt={2}>
        <Button width={1} href={registerUrl} variant="secondary">
          {t("login.promo.signUpNow", "Sign Up Now")}
        </Button>
      </Box>
    </>
  );
};
